<template>
  <div class="d-inline-block" style="max-width: 100%">
      <v-avatar :tile="tile" :size="size">
        <template v-if="photoUrl != null">
          <v-img :src="photoUrl" :alt="`${schoolName}`"></v-img>
        </template>
      </v-avatar>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import db from '@/firebase/init'
import firebase from 'firebase'
import rules from '@/rules'

export default {
  name: 'SchoolPhoto',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    id: String,
    size: null,
    tile: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      photoUrl: null,
      schoolName: null,
      job: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.user.data,
      status: state => state.user.status,
      currentUser: state => state.user.user,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'id': function (val) {
      this.getUrl(val)
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', [
      'uploadPhoto'
    ]),

    getUrl(id) {
      if(id) {
        db.collection('schools')
        .doc(id)
        .get()
        .then(doc => {
            if (doc.exists) {
                let school = doc.data()
                school.id = doc.id
                school.ref = doc.ref
                this.schoolName = school.schoolName
                let storage  = firebase.storage()
                storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                .then(url => {
                    this.photoUrl = url
                })
                .catch(error => {
                    console.log(error.message)
                    storage.ref(`schoollogos/${school.logo}`).getDownloadURL()
                    .then(url => {
                        this.photoUrl = url
                    })
                })
            }
        })
        .catch(error => {
            console.log(error.message)
        })

      }

    },

  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.getUrl(this.id)
  }

}

</script>
